const axios = require('axios');

var baseUrl

if(process.env.NODE_ENV === 'development') {
  baseUrl = '/backend/'
} else if (process.env.NODE_ENV === 'test') {
  baseUrl = '/backend/'
} else {
  baseUrl = window._env_.API_URL || 'http://localhost:54636/api/'
}

const TOKEN = window._env_.TOKEN || 'NUtAd3AkQGQ3RFJ6OWV2Qzh3JTkqSCRWQUtDNkltc01vdEh5bkdmUw=='

const RequestAPI = axios.create({
  baseURL: baseUrl,
  headers: {
    'X-API-KEY': TOKEN
  }
})

export { RequestAPI, TOKEN }