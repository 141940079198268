import React, { useEffect, useState } from 'react';
import HorizontalScroll from 'react-scroll-horizontal';
import Skeleton from 'react-loading-skeleton';
import { PieChart } from 'react-minimal-pie-chart';
import ReactStars from 'react-rating-stars-component';

//components
import { RequestAPI } from '../../Api/app';
import { useGlobal } from '../../Context/GlobalContext';
import SvgLines from 'react-mt-svg-lines';
import ReactWordcloud from 'react-wordcloud';

//scss
import './Home.scss';

export default function Home(props) {
    const { changePage } = useGlobal();

    const [loadCampanhas, setLoadCampanhas] = useState(true);
    const [campanhas, setCampanhas] = useState([]);
    const [campanhaSelecionada, setCampanhaSelecionada] = useState({});

    const [loadPerguntas, setLoadPerguntas] = useState(true);
    const [perguntas, setPerguntas] = useState([]);

    const COLORSCHART = [
        '#ECBC7A',
        '#E381B5',
        '#A6CFD7',
        '#2A59B2',
        '#666666',
        '#CCCCCC',
        '#ADD2CA',
        '#836FFF',
        '#00BFFF',
        '#778899',
        '#00FFFF',
        '#40E0D0',
        '#7FFFD4',
        '#00FA9A',
        '#006400',
        '#ADFF2F',
    ];

    useEffect(() => {
        changePage('name', 'Dashboard');
        getCampanhas();
    }, [props]);

    useEffect(() => {
        if (campanhas.length > 0) setCampanhaSelecionada(campanhas[0]);
    }, [campanhas]);

    useEffect(() => {
        if (campanhaSelecionada.Id) {
            getPerguntas();
        }
    }, [campanhaSelecionada]);

    async function getCampanhas() {
        setLoadCampanhas(true);
        const response = await RequestAPI.get('Campanha');
        setCampanhas([...response.data.ObjRetorno]);
        setLoadCampanhas(false);
    }

    useEffect(() => {
        let rotationInterval = setInterval(() => {
            getPerguntas(true);
        }, 5000);

        return () => {
            clearInterval(rotationInterval);
        };
    }, [campanhaSelecionada]);

    async function getPerguntas(load = false) {
        if (load === false) setLoadPerguntas(true);
        const response = await RequestAPI.post('Perguntas', {
            IdCampanha: campanhaSelecionada.Id,
        });
        const perguntas = [];
        const loop = async (row) => {
            var respostas = await RequestAPI.post('Respostas', {
                IdPergunta: row.Id,
            });
            row.respostas = respostas.data.ObjRetorno;
            row.chart = [];

            respostas.data.ObjRetorno.map((resposta, respostaKey) => {
                row.chart.push({
                    title: resposta.Resposta,
                    text: resposta.Resposta,
                    value: resposta.Total,
                    color: COLORSCHART[respostaKey],
                });
            });
            perguntas.push(row);
            return Promise.resolve(row);
        };
        let responses = await Promise.all(
            response.data.ObjRetorno.map((row) => loop(row))
        );
        console.log(perguntas);
        console.log(responses);
        setPerguntas([...responses]);
        setLoadPerguntas(false);
    }

    return (
        <div id="home">
            <h5>Campanhas</h5>

            <div className="list-campgain">
                <HorizontalScroll reverseScroll={true}>
                    {loadCampanhas === true &&
                        [0, 1, 2, 3, 4].map((row, key) => (
                            <Skeleton className="skeleton" />
                        ))}
                    {loadCampanhas === false &&
                        campanhas.map((row, key) => (
                            <div
                                onClick={() => setCampanhaSelecionada(row)}
                                className={`item ${
                                    row.Id === campanhaSelecionada.Id
                                        ? 'active'
                                        : 'inactive'
                                }`}
                                key={key}
                            >
                                <div className="icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                        role="img"
                                        width="1em"
                                        height="1em"
                                        preserveAspectRatio="xMidYMid meet"
                                        viewBox="0 0 32 32"
                                    >
                                        <path
                                            d="M4 20v2h4.586L2 28.586L3.414 30L10 23.414V28h2v-8H4z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M24 21h2v5h-2z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M20 16h2v10h-2z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M16 18h2v8h-2z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M28 2H4a2.002 2.002 0 0 0-2 2v12h2v-3h24.001l.001 15H16v2h12a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2zm-16 9H4V4h8zm2 0V4h14v7z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </div>

                                <b>{row.Descricao}</b>
                                <i>#{row.Id}</i>
                            </div>
                        ))}
                </HorizontalScroll>
            </div>

            <div className="list-questions">
                {loadPerguntas === true &&
                    [0, 1, 2, 3, 4, 5].map((row, key) => (
                        <div className="skeleton">
                            <Skeleton />
                        </div>
                    ))}

                {loadPerguntas === false &&
                    perguntas
                        .filter(
                            (obj) =>
                                obj.TipoPergunta === 'Resposta Direta' ||
                                obj.TipoPergunta === 'Qualidade da Base'
                        )
                        .map((row, key) => (
                            <div className="question" key={key}>
                                <div className="header">
                                    <div className="icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            role="img"
                                            width="1em"
                                            height="1em"
                                            preserveAspectRatio="xMidYMid meet"
                                            viewBox="0 0 16 16"
                                        >
                                            <g fill="none">
                                                <path
                                                    d="M9.5 9a1.5 1.5 0 0 1 1.5 1.5v.5c0 1.971-1.86 4-5 4c-3.14 0-5-2.029-5-4v-.5A1.5 1.5 0 0 1 2.5 9h7zm0 1h-7a.5.5 0 0 0-.5.5v.5c0 1.438 1.432 3 4 3s4-1.562 4-3v-.5a.5.5 0 0 0-.5-.5zM13 7a.75.75 0 1 1 0 1.5a.75.75 0 0 1 0-1.5zM6 2.5A2.75 2.75 0 1 1 6 8a2.75 2.75 0 0 1 0-5.5zm0 1A1.75 1.75 0 1 0 6 7a1.75 1.75 0 0 0 0-3.5zM13 1a2 2 0 0 1 2 2c0 .73-.212 1.14-.754 1.708l-.264.27c-.377.393-.482.605-.482 1.022a.5.5 0 0 1-1 0c0-.73.212-1.14.754-1.708l.264-.27C13.895 3.63 14 3.418 14 3a1 1 0 1 0-2 0a.5.5 0 0 1-1 0a2 2 0 0 1 2-2z"
                                                    fill="currentColor"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <p>{row.Pergunta}</p>
                                </div>

                                <div className="chart">
                                    <PieChart
                                        data={row.chart}
                                        label={({ dataEntry }) =>
                                            `${Math.round(
                                                dataEntry.percentage
                                            )} %`
                                        }
                                        animate={true}
                                        labelStyle={{
                                            fontSize: '5px',
                                        }}
                                    />

                                    <div className="legends">
                                        {row.chart.map(
                                            (resposta, respostaKey) => (
                                                <div className="legend">
                                                    <i
                                                        style={{
                                                            backgroundColor:
                                                                COLORSCHART[
                                                                    respostaKey
                                                                ],
                                                        }}
                                                    ></i>
                                                    <span>
                                                        {resposta.title} (
                                                        {resposta.value})
                                                    </span>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}

                {loadPerguntas === false &&
                    perguntas
                        .filter((obj) => obj.TipoPergunta === 'Totalizador')
                        .map((row, key) => (
                            <div className="question" key={key}>
                                <div className="header">
                                    <div className="icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            role="img"
                                            width="1em"
                                            height="1em"
                                            preserveAspectRatio="xMidYMid meet"
                                            viewBox="0 0 16 16"
                                        >
                                            <g fill="none">
                                                <path
                                                    d="M9.5 9a1.5 1.5 0 0 1 1.5 1.5v.5c0 1.971-1.86 4-5 4c-3.14 0-5-2.029-5-4v-.5A1.5 1.5 0 0 1 2.5 9h7zm0 1h-7a.5.5 0 0 0-.5.5v.5c0 1.438 1.432 3 4 3s4-1.562 4-3v-.5a.5.5 0 0 0-.5-.5zM13 7a.75.75 0 1 1 0 1.5a.75.75 0 0 1 0-1.5zM6 2.5A2.75 2.75 0 1 1 6 8a2.75 2.75 0 0 1 0-5.5zm0 1A1.75 1.75 0 1 0 6 7a1.75 1.75 0 0 0 0-3.5zM13 1a2 2 0 0 1 2 2c0 .73-.212 1.14-.754 1.708l-.264.27c-.377.393-.482.605-.482 1.022a.5.5 0 0 1-1 0c0-.73.212-1.14.754-1.708l.264-.27C13.895 3.63 14 3.418 14 3a1 1 0 1 0-2 0a.5.5 0 0 1-1 0a2 2 0 0 1 2-2z"
                                                    fill="currentColor"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <p>{row.Pergunta}</p>
                                </div>

                                <div className="total">
                                    <span>{row.respostas[0].Total}</span>
                                </div>
                            </div>
                        ))}

                {loadPerguntas === false &&
                    perguntas
                        .filter((obj) => obj.TipoPergunta === 'NPS')
                        .map((row, key) => (
                            <div className="question" key={key}>
                                <div className="header">
                                    <div className="icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            role="img"
                                            width="1em"
                                            height="1em"
                                            preserveAspectRatio="xMidYMid meet"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                d="M10 1L7 7l-6 .75l4.13 4.62L4 19l6-3l6 3l-1.12-6.63L19 7.75L13 7zm0 2.24l2.34 4.69l4.65.58l-3.18 3.56l.87 5.15L10 14.88l-4.68 2.34l.87-5.15l-3.18-3.56l4.65-.58z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <p>{row.Pergunta}</p>
                                </div>

                                {row.TipoPergunta === 'NPS' &&
                                    row.chart.map((resposta, respostakey) => (
                                        <div
                                            className="rating"
                                            key={respostakey}
                                        >
                                            <ReactStars
                                                count={5}
                                                value={resposta.value}
                                                edit={false}
                                                activeColor="#ffd700"
                                                size={30}
                                            />
                                        </div>
                                    ))}
                            </div>
                        ))}

                {loadPerguntas === false &&
                    perguntas
                        .filter((obj) => obj.TipoPergunta === 'Nuvem de Palavras')
                        .map((row, key) => (
                            <div className="question" key={key}>
                                <div className="header">
                                    <div className="icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            role="img"
                                            width="1em"
                                            height="1em"
                                            preserveAspectRatio="xMidYMid meet"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                d="M10 1L7 7l-6 .75l4.13 4.62L4 19l6-3l6 3l-1.12-6.63L19 7.75L13 7zm0 2.24l2.34 4.69l4.65.58l-3.18 3.56l.87 5.15L10 14.88l-4.68 2.34l.87-5.15l-3.18-3.56l4.65-.58z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <p>{row.Pergunta}</p>
                                </div>

                                <ReactWordcloud words={row.chart} />
                            </div>
                        ))}

                {loadPerguntas === false &&
                    perguntas
                        .filter(
                            (obj) => obj.TipoPergunta === 'Nuvem de Palavras'
                        )
                        .map((row, key) => (
                            <div className="question" key={key}>
                                <div className="header">
                                    <div className="icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            role="img"
                                            width="1em"
                                            height="1em"
                                            preserveAspectRatio="xMidYMid meet"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                d="M10 1L7 7l-6 .75l4.13 4.62L4 19l6-3l6 3l-1.12-6.63L19 7.75L13 7zm0 2.24l2.34 4.69l4.65.58l-3.18 3.56l.87 5.15L10 14.88l-4.68 2.34l.87-5.15l-3.18-3.56l4.65-.58z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <p>{row.Pergunta}</p>
                                </div>

                                <ReactWordcloud words={row.chart} />
                            </div>
                        ))}
            </div>

            
        </div>
    );
}
